import React from 'react'
import { Link } from 'react-router-dom'

import './Team.css'


const Team =() => {
  return(
    <div class="team_section team_section_0 team_with_background et_section_regular snipcss-njMhf">
    <div class="team_row team_row_0">
        <div class="team_column team_column_1_3 team_column_0  team_css_mix_blend_mode_passthrough">
            <div class="team_module team_text team_text_0  team_text_align_left team_bg_layout_light">
                <div class="team_text_inner">
                    <img class="team_logo" decoding="async" src="/images/sportstake25.png" alt="" title="Artboard – 1"/>
                </div>
            </div>
            <div class="team_module team_text team_text_1  team_text_align_left team_bg_layout_light">
                <div class="team_text_inner">
                <button className='team_nav_button'><Link to="/Overview" className='team_button_text'>Overview</Link></button>
                <button className='team_nav_button'><Link to="/FocusAreas" className='team_button_text'>Focus Areas</Link></button>
                <button className='team_nav_button'><Link to="/Services" className='team_button_text'>Services</Link></button>
                <button className='team_nav_button team_nav_button_border'><Link to="/" className='team_button_text'>Team</Link></button>
                <button className='team_nav_button'><Link to="/VisionMission" className='team_button_text'>Vision & Mission</Link></button>
                </div>
            </div>
            
        </div>
        <div class="team_column team_column_1_3 team_column_1  team_css_mix_blend_mode_passthrough">
            <div class="team_module team_text team_text_2  team_text_align_center team_bg_layout_light">
                <div class="team_text_inner">
                    <h6>Larry Michael Dube</h6>
                </div>
            </div>
            
            <div class="team_module team_image team_image_0">
                <span class="team_image_wrap "><img fetchpriority="high" decoding="async" width="1335" height="1335" src="/images/sportstake36.png" alt="" title="portrait-handsome-bearded-male" class="wp-image-87"/></span>
            </div>
            <div class="team_module team_text team_text_4  team_text_align_center team_bg_layout_light">
                <div class="team_text_inner">
                    <p>An innovator majoring in Economics with a focus on investment analysis, emerging markets, information technology , sports data development , and perfomance. A Proffesional at market intelligence holding a B.Com Economics (hons) and languages.</p>
                </div>
            </div>
        </div>
        <div class="team_column team_column_1_3 team_column_2  team_css_mix_blend_mode_passthrough et-last-child">
            <div class="team_module team_text team_text_5  team_text_align_center team_bg_layout_light">
                <div class="team_text_inner">
                    <h6>Paul Ntonya</h6>
                </div>
            </div>
            
            <div class="team_module team_image team_image_1">
                <span class="team_image_wrap "><img fetchpriority="high" decoding="async" width="1335" height="1335" src="/images/sportstake37.png" alt="" title="portrait-handsome-bearded-male" class="wp-image-87"/></span>
            </div>
            <div class="team_module team_text team_text_7  team_text_align_center team_bg_layout_light">
                <div class="team_text_inner">
                    <p>A growing business developer & strategic systems thinker with a knack for spotting opportunities & Synergy development across Technology, Arts & economics 
Certified Google digital marketer as well as a versatile creative with interests in sports, creative ecosystems development, Fashion and Apparel design.
PFSA UK certified Scout</p>
                </div>
            </div>
        </div>
    </div>
    <div class="team_row team_row_1">
        <div class="team_column team_column_1_3 team_column_3  team_css_mix_blend_mode_passthrough team_column_empty">
        </div>
        <div class="team_column team_column_1_3 team_column_4  team_css_mix_blend_mode_passthrough">
        <div class="contact-container">
                <div class="contact-item">
                    <div class="contact-icon">&#x1F4CD;</div>
                    <div class="contact-text">Harare | Lusaka | Lilongwe</div>
                </div>
                <div class="contact-item">
                    <div class="contact-icon">&#x2709;&#xFE0F;</div>
                    <div class="contact-text">info@sportstakelabs.com</div>
                </div>
                <div class="contact-item">
                    <div class="contact-icon">&#x260E;&#xFE0F;</div>
                    <div class="contact-text">+263 77 313 2356</div>
                </div>
                <div class="social-media">
                    <div class="social-icon">
                        <a href="" target="_blank"><img src="/images/twitter.png" alt="X"/></a> 
                    </div>
                    <div class="social-icon">
                        <a href="" target="_blank"><img src="/images/facebook.png" alt="Facebook"/></a> 
                    </div>
                    <div class="social-icon">
                        <a href="" target="_blank"><img src="/images/instagram.png" alt="Instagram"/></a> 
                    </div>
                    <div class="social-icon">
                        <a href="https://wa.me/263773132356" target="_blank"><img src="/images/whatsapp.png" alt="WhatsApp"/></a> 
                    </div>
                </div>
            </div> 
        </div>
        <div class="team_column team_column_1_3 team_column_5  team_css_mix_blend_mode_passthrough et-last-child">
            <div class="contact-container">
                <div class="contact-item">
                    <div class="contact-icon">&#x1F4CD;</div>
                    <div class="contact-text">Harare | Lusaka | Lilongwe</div>
                </div>
                <div class="contact-item">
                    <div class="contact-icon">&#x2709;&#xFE0F;</div>
                    <div class="contact-text">info@sportstakelabs.com</div>
                </div>
                <div class="contact-item">
                    <div class="contact-icon">&#x260E;&#xFE0F;</div>
                    <div class="contact-text">+263 77 313 2356</div>
                </div>
                <div class="social-media">
                    <div class="social-icon">
                        <a href="" target="_blank"><img src="/images/twitter.png" alt="X"/></a> 
                    </div>
                    <div class="social-icon">
                        <a href="" target="_blank"><img src="/images/facebook.png" alt="Facebook"/></a> 
                    </div>
                    <div class="social-icon">
                        <a href="" target="_blank"><img src="/images/instagram.png" alt="Instagram"/></a> 
                    </div>
                    <div class="social-icon">
                        <a href="https://wa.me/263773132356" target="_blank"><img src="/images/whatsapp.png" alt="WhatsApp"/></a> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Team
