import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import './index.css';
import App from './App';
import FocusAreas from './pages/FocusAreas';
import Services from './pages/Services';
import Overview from './pages/Overview';
import Team from './pages/Team';
import VisionMission from './pages/VisionMission';

import 'bootstrap/dist/css/bootstrap.min.css';

const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>
    },
    {
        path: "/FocusAreas",
        element: <FocusAreas/>
    },
    {
        path: "/Services",
        element: <Services/>
    },
    {
        path: "/Overview",
        element: <Overview/>
    },
    {
        path: "/Team",
        element: <Team/>
    },
    {
        path: "/VisionMission",
        element: <VisionMission/>
    }
  ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);
