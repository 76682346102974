import React from 'react'
import { Link } from "react-router-dom";
/*import FocusAreas from '../pages/FocusAreas';*/

import './Overview.css'

const Header = () => {
  return (
    <div class="ov_section ov_section_0 ov_with_background et_section_regular overview_0-0-0-1 overview_-XiDbv">
    <div class="ov_row ov_row_0 overview_0-1-1-2">
        <div class="ov_column ov_column_1_3 ov_column_0 ov_css_mix_blend_mode_passthrough overview_0-2-2-3">
            <div class="ov_module ov_text ov_text_0 ov_text_align_left ov_bg_layout_light overview_0-3-3-4">
                <div class="ov_text_inner overview_0-4-4-5">
                    <img class="ov_logo" decoding="async" src="/images/sportstake30.png" alt="" title="Artboard – 1"/>
                </div>
            </div>
            <div class="ov_module ov_text ov_text_1 ov_text_align_left ov_bg_layout_light overview_0-3-3-7">
                <div class="ov_text_inner overview_0-4-7-8">
                <button className='ov_nav_button ov_nav_button_border'><Link to="/" className='ov_button_text'>Overview</Link></button>
                <button className='ov_nav_button'><Link to="/FocusAreas" className='ov_button_text'>Focus Areas</Link></button>
                <button className='ov_nav_button'><Link to="/Services" className='ov_button_text'>Services</Link></button>
                <button className='ov_nav_button'><Link to="/Team" className='ov_button_text'>Team</Link></button>
                <button className='ov_nav_button'><Link to="/VisionMission" className='ov_button_text'>Vision & Mission</Link></button>
                </div>
            </div>
        </div>
        <div class="ov_column ov_column_2_3 ov_column_1 ov_css_mix_blend_mode_passthrough et-last-child overview_0-2-2-19">
            <div class="ov_module ov_text ov_text_2 ov_text_align_center ov_bg_layout_light overview_0-3-19-20">
                <div class="ov_text_inner overview_0-4-20-21">
                    <p class="overview_0-5-21-22">About Us</p>
                </div>
            </div>
            <div class="ov_module ov_text ov_text_3 ov_text_align_center ov_bg_layout_light overview_0-3-19-23">
                <div class="ov_text_inner overview_0-4-23-24">
                    <p class="overview_0-5-24-25">We are a sports business development, brand strategy, athlete management, and data analytics consulting firm built to help contribute to the sports sector’s development. We bring talent, expertise, and skills that can contribute towards the development of the sports economy and market by unlocking value for stakeholders through data-oriented business and industry development processes that will deliver robust value for athletes, teams/clubs, and governing organizations.</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Header
