import './App.css';
import React, {useState, useEffect} from 'react';
/*import Header from './components/Header';*/
import Overview from './pages/Overview';
import FadeLoader from "react-spinners/FadeLoader";


function App() {
  const [loading, setloading] = useState(false);
  
  useEffect(() => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, [])
  return (
    <div className="App">
      {
        loading ?(
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' }}>
          <FadeLoader
            color={"#fc6a03"}
            loading={loading}
            size={25}
          />
        </div>
      ) : (

      <Overview/>
      )
      }
    </div>
  );
}

export default App;
