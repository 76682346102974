import React from 'react'
import { Link } from "react-router-dom";
/*import FocusAreas from '../pages/FocusAreas';*/

import './VisionMission.css'

const Header = () => {
  return (
    <div class="vi_mi_2_section vi_mi_2_section_0 et_section_regular">
    <div class="vi_mi_row vi_mi_row_0 vi_mi_gutters2 vi_mi_row_1-4_3-4 snipcss-4ooCh">
    <div class="vi_mi_column vi_mi_column_1_4 vi_mi_column_0  vi_mi_css_mix_blend_mode_passthrough">
        <div class="vi_mi_module vi_mi_text vi_mi_text_0  vi_mi_text_align_left vi_mi_bg_layout_light">
            <div class="vi_mi_text_inner">
                <img class="vi_mi_logo" decoding="async" src="/images/sportstake21.png" alt="" title="Artboard – 1"/>
            </div>
        </div>
        <div class="vi_mi_module vi_mi_text vi_mi_text_1  vi_mi_text_align_left vi_mi_bg_layout_light">
            <div class="vi_mi_text_inner">
            <button className='vi_mi_nav_button'><Link to="/Overview" className='vi_mi_button_text'>Overview</Link></button>
                <button className='vi_mi_nav_button'><Link to="/FocusAreas" className='vi_mi_button_text'>Focus Areas</Link></button>
                <button className='vi_mi_nav_button'><Link to="/Services" className='vi_mi_button_text'>Services</Link></button>
                <button className='vi_mi_nav_button'><Link to="/Team" className='vi_mi_button_text'>Team</Link></button>
                <button className='vi_mi_nav_button vi_mi_nav_button_border'><Link to="/" className='vi_mi_button_text'>Vision & Mission</Link></button>
            </div>
        </div>
    </div>
    <div class="vi_mi_column vi_mi_column_3_4 vi_mi_column_1  vi_mi_css_mix_blend_mode_passthrough et-last-child">
        <div class="vi_mi_module vi_mi_text vi_mi_text_2  vi_mi_text_align_left vi_mi_bg_layout_light">
            <div class="vi_mi_text_inner">
                <h2>Mission</h2>
            </div>
        </div>
        <div class="vi_mi_module vi_mi_text vi_mi_text_3  vi_mi_text_align_left vi_mi_bg_layout_light">
            <div class="vi_mi_text_inner">
                <p>Our objective is to deliver\establish Africa’s 1st bona-fide sports business development Accelerator with a Pan-African footprint, churning out top notch sports industry innovation to get us up to speed with Global standards. Kickstarting a sports business development revolution in Zimbabwe then onto Africa, developing &amp; accelerating a myriad of high growth value streams for different stakeholders that incl artists, athletes, teams, institutions among others by creating synergies within connected industries that offer value to sports development, the creative economy and administrative boardies among others by leveraging analytics, open data development to guide business \organizational processes to drive innovation and uptake of cutting edge solutions that enrich the sports development ecosystem in Zim and beyond.</p>
                <p>Enabling interface between Private and public players through collaboration aimed at Unlocking capital and growth finance backed by extensive data oriented design thinking processes across branding, marketing, fan engagement, etc with growth paths like robust content development, financial management, sponsorship dealflow etc</p>
            </div>
        </div>
        <div class="vi_mi_module vi_mi_divider vi_mi_divider_0 vi_mi_divider_position_ vi_mi_space">
            <div class="vi_mi_divider_internal"></div>
        </div>
    </div>
    </div>
    <div class="vi_mi_2_row vi_mi_2_row_1 snipcss-joaNb">
    <div class="vi_mi_2_column vi_mi_2_column_4_4 vi_mi_2_column_2  vi_mi_2_css_mix_blend_mode_passthrough et-last-child">
        <div class="vi_mi_2_module vi_mi_2_text vi_mi_2_text_4  vi_mi_2_text_align_left vi_mi_2_bg_layout_light">
            <div class="vi_mi_2_text_inner">
                <h2>Vision</h2>
            </div>
        </div>
        <div class="vi_mi_2_module vi_mi_2_text vi_mi_2_text_5  vi_mi_2_text_align_center vi_mi_2_bg_layout_light">
            <div class="vi_mi_2_text_inner">
                <p>To become a major contributor to Zimbabwean &amp; more broadly, African Sports Industry development, helping build solid and expansive business processes &amp;frameworks deployed via different value streams in Media, Marketing, Apparel, Design works, financial management &amp; more. , All structured towards fostering collaboration among all stakeholders to work cohesively on delivering a highly deliberate operational framework including Government Governing boardies, development agencies, teams, creatives and athletes to harness broad &amp; high growth monetary value which will contribute significantly to local communities, GDP &amp; the economy at large</p>
            </div>
        </div>
        <div class="vi_mi_2_module vi_mi_2_divider vi_mi_2_divider_1 vi_mi_2_divider_position_ vi_mi_2_space">
            <div class="vi_mi_2_divider_internal"></div>
        </div>
    </div>
    </div>
    </div>
  )
}

export default Header
